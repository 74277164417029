<template>
    <div class="award_list_wrap">
        <!--  -if문 사용해서 레이아웃 분리  "1등만"   -->
        <div class="main_award_1th_wrap" v-if="stepLayout === 1"
             @click="moveItemDetail(firstData[0])">
            <div class="award_item_wrap">
                <img :src="returnImage(firstData[0].Item.ItemFile.Item,1200,697)" alt="">
                <div class="award_1th">{{$t('1th')}}</div>
            </div>
            <div class="item_info_wrap">
                <div class="user_profile"
                     :style="`background-image: url(${returnImage(firstData[0].Item.profile.originator)});`"></div>
                <div class="user_nick">{{firstData[0].Item.originator_nick}}</div>
                <div class="item_vote"></div>
                <div class="vote_count">{{returnCommas(firstData[0].ci_vote_cnt)}}({{returnPercentage(firstData[0].ci_vote_cnt,firstData[0].totalVote)}}%)</div>
                <div class="award_result_btn_wrap">
                    <button @click="openResultModal(firstData[0].ci_result,$event)">{{ $t('awardResult') }}</button>
                </div>
                <div class="item_subject">
                    {{firstData[0].Item.i_name}}
                </div>
            </div>
        </div>

        <!--  -if문 사용해서 레이아웃 분리  "1,2등만"   -->
        <div class="main_award_2th_wrap" v-if="stepLayout === 2">
            <div class="award_item_wrap"
                 @click="moveItemDetail(firstData[0])">
                <div class="user_award_item listIndex--1">
                    <img :src="returnImage(firstData[0].Item.ItemFile.Item,570,331)" alt="">
                    <div :class="`award_${idx}th`">{{$t('1th')}}</div>
                </div>
                <div class="item_info_wrap">
                    <div class="user_profile"
                         :style="`background-image: url(${returnImage(firstData[0].Item.profile.originator)});`"></div>
                    <div class="user_nick">{{firstData[0].Item.originator_nick}}</div>
                    <div class="item_vote"></div>
                    <div class="vote_count">{{returnCommas(firstData[0].ci_vote_cnt)}}({{returnPercentage(firstData[0].ci_vote_cnt,firstData[0].totalVote)}}%)</div>
                    <div class="award_result_btn_wrap">
                        <button @click="openResultModal(firstData[0].ci_result,$event)">{{ $t('awardResult') }}</button>
                    </div>
                    <div class="item_subject">
                        {{firstData[0].Item.i_name}}
                    </div>
                </div>
            </div>
            <div class="award_item_wrap" :class="`th2`"
                 @click="moveItemDetail(secondData[0])">
                <div class="user_award_item listIndex--3">
                    <img :src="returnImage(secondData[0].Item.ItemFile.Item,570,331)" alt="">
                    <div :class="`award_${idx+1}th`">{{$t('2th')}}</div>
                </div>
                <div class="item_info_wrap">
                    <div class="user_profile"
                         :style="`background-image: url(${returnImage(secondData[0].Item.profile.originator)});`"></div>
                    <div class="user_nick">{{secondData[0].Item.originator_nick}}</div>
                    <div class="item_vote"></div>
                    <div class="vote_count">{{returnCommas(secondData[0].ci_vote_cnt)}}({{returnPercentage(secondData[0].ci_vote_cnt,secondData[0].totalVote)}}%)</div>
                    <div class="award_result_btn_wrap">
                        <button @click="openResultModal(secondData[0].ci_result,$event)">{{ $t('awardResult') }}</button>
                    </div>
                    <div class="item_subject">
                        {{secondData[0].Item.i_name}}
                    </div>
                </div>
            </div>
        </div>

        <!--   1~7등 default Layout ------>
        <div class="main_award_wrap" v-if="stepLayout === 3">
            <div class="award_top_section_wrap">
                <div class="award_item_1th_wrap"
                     @click="moveItemDetail(firstData[0])">
                    <div class="user_award_item listIndex--1">
                        <img :src="returnImage(firstData[0].Item.ItemFile.Item,570,331)" alt="">
                        <div :class="`award_${idx}th`">{{$t('1th')}}</div>
                    </div>
                    <div class="item_info_wrap">
                        <div class="user_profile"
                             :style="`background-image: url(${returnImage(firstData[0].Item.profile.originator)});`"></div>
                        <div class="user_nick">{{firstData[0].Item.originator_nick}}</div>
                        <div class="item_vote"></div>
                        <div class="vote_count">{{returnCommas(firstData[0].ci_vote_cnt)}}({{returnPercentage(firstData[0].ci_vote_cnt,firstData[0].totalVote)}}%)</div>
                        <div class="award_result_btn_wrap">
                            <button @click="openResultModal(firstData[0].ci_result,$event)">{{ $t('awardResult') }}</button>
                        </div>
                        <div class="item_subject">
                            {{firstData[0].Item.i_name}}
                        </div>
                    </div>
                </div>
                <div class="right_wrap">
                    <div class="award_item_wrap" :class="`th2`"
                         @click="moveItemDetail(secondData[0])">
                        <div class="user_award_item listIndex--2">
                            <img :src="returnImage(secondData[0].Item.ItemFile.Item,290,169)" alt="">
                            <div :class="`award_2th`">{{$t('2th')}}</div>
                        </div>
                        <div class="item_info_wrap">
                            <div class="user_profile"
                                 :style="`background-image: url(${returnImage(secondData[0].Item.profile.originator)});`"></div>
                            <div class="user_nick">{{secondData[0].Item.originator_nick}}</div>
                            <div class="award_result_btn_wrap">
                                <button @click="openResultModal(secondData[0].ci_result,$event)">{{ $t('awardResult') }}</button>
                            </div>
                            <div class="item_subject">
                                {{secondData[0].Item.i_name}}
                            </div>
                            <div class="vote_wrap" style="margin-top: 23px;">
                                <div class="item_vote"></div>
                                <div class="vote_count">{{returnCommas(secondData[0].ci_vote_cnt)}}({{returnPercentage(secondData[0].ci_vote_cnt,secondData[0].totalVote)}}%)</div>
                            </div>
                        </div>
                    </div>
                    <div class="award_item_wrap" :class="`th3`"
                         @click="moveItemDetail(thirdData[0])">
                        <div class="user_award_item listIndex--3">
                            <img :src="returnImage(thirdData[0].Item.ItemFile.Item,290,169)"  alt="">
                            <div :class="`award_3th`">{{$t('3th')}}</div>
                        </div>
                        <div class="item_info_wrap">
                            <div class="user_profile"
                                 :style="`background-image: url(${returnImage(thirdData[0].Item.profile.originator)});`"></div>
                            <div class="user_nick">{{thirdData[0].Item.originator_nick}}</div>
                            <div class="award_result_btn_wrap">
                                <button @click="openResultModal(thirdData[0].ci_result,$event)">{{ $t('awardResult') }}</button>
                            </div>
                            <div class="item_subject">
                                {{thirdData[0].Item.i_name}}
                            </div>
                            <div class="vote_wrap" style="margin-top: 23px;">
                                <div class="item_vote"></div>
                                <div class="vote_count">{{returnCommas(thirdData[0].ci_vote_cnt)}}({{returnPercentage(thirdData[0].ci_vote_cnt,thirdData[0].totalVote)}}%)</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bottom_wrap">
                <div class="award_item_wrap" v-for="award in otherData" :key="`award${listLayouts}`"
                     @click="moveItemDetail(award)">
                    <div class="user_award_item">
                        <img :src="returnImage(award.Item.ItemFile.Item,275,160)" >
                        <div class="award_th" :id="`${award.ci_rank}`">{{ award.ci_rank}}{{$t('th')}}</div>
                    </div>
                    <div class="item_info_wrap">
                        <div class="user_profile"
                             :style="`background-image: url(${returnImage(award.Item.profile.originator)});`"></div>
                        <div class="user_nick">{{award.Item.originator_nick}}</div>
                        <div class="award_result_btn_wrap">
                            <button @click="openResultModal(award.ci_result,$event)">{{ $t('awardResult') }}</button>
                        </div>
                        <div class="item_subject">
                            {{award.Item.i_name}}
                        </div>
                        <div class="vote_wrap" style="margin-top: 23px;">
                            <div class="item_vote"></div>
                            <div class="vote_count">{{returnCommas(award.ci_vote_cnt)}}({{returnPercentage(award.ci_vote_cnt,award.totalVote)}}%)</div>
                        </div>
                    </div>
                </div>
                <div class="clear"></div>
            </div>
        </div>
    </div>

    <!--  -->
</template>

<script>
import util from "@/mixins/util";
import imageOption from "@/mixins/imageOption";
import imageResizeMixins from "@/mixins/imageResizeMixins";

export default {
    name: "AwradListDefaultLayout",
    mixins: [imageOption, imageResizeMixins],
    components: {
    },
    inject: [],
    provide() {
        return{

        }
    },
    props: {
        awardList: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data() {
        return{
            idx: 1,
            stepLayout: '',
            listLayouts: 7,
            firstData : [],
            secondData :[],
            thirdData :[],
            otherData : []
        }
    },
    beforeRouterEnter() {},
    created() {
    },
    mounted() {
        this.initData()
    },
    beforeDestroy() {
    },
    destroyed() {},
    computed: {
    },
    methods:{
        openResultModal(reason,e) {
            e.stopPropagation()
            this.$modal.show('judge-result-modal', {reason: reason});
        },
        initData(){
            this.firstData = this.awardList.filter(v => v.ci_rank === 1)
            this.secondData =this.awardList.filter(v => v.ci_rank === 2)
            this.thirdData =this.awardList.filter(v => v.ci_rank === 3)
            this.otherData = this.awardList.filter(v => {
                return v.ci_rank > 3
            })

            if(this.awardList.length >= 3 ){
                this.stepLayout = 3
            }else {
                this.stepLayout = this.awardList.length
            }
        },
        returnImage(image,width=30,height=30, f='jpg') {
            if (!util.isEmpty(image)) {
                return `${image[0].org_url}?${this.setImageOptions(width , height , f)}`
            }
            return ''
        },
        returnCommas(x=0) {
            //return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return util.Number.numFormat(x);
        },
        returnPercentage(v,t){
            if(t !== 0){
                return parseFloat((v / t * 100).toFixed(2))
            }else {
                return 0
            }
        },
        returnDetail(type){
            return this[type][0].Item.ItemFile.Item
        },
        movePage(url){
            this.$router.push(url)
        },
        moveItemDetail(data) {
            if(util.isEmpty(data) || util.isEmpty(data.Item)) {
                return false;
            }
            this.$router.push('/item/'+data.Item.i_sku);
        },
    },
    watch: {

    },
}
</script>

<style scoped>

</style>
